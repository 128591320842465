<template>
  <div class="out">
    <div class="header">
        <div class="back" :style="$i18n.locale=='en'?'white-space: nowrap;width:0.7rem':''" @click="$router.back()">< {{$t('main.return')}}</div>
    </div>
    <div class="wallpaper">
        <img :src="item.img"  v-for="item in list" :key="item.id" alt="">
    </div>
    <div class="btn-wrap">
        <div class="btn" @click="showPopBtn">{{$t('main.fsplyci')}}</div>
    </div>
    <div class="pop-wrap"  v-if="showPop" >
        <div class="pop">
            <div class="title"> <img src="../assets/img/left-jiantou.png" alt=""> {{$t('main.pfyci')}} <img src="../assets/img/right-jiantou.png" alt=""> </div>
            <div class="guanbi"><img  src="../assets/img/guanbi.png" alt="" @click="showPop = false"></div>
            <div class="xingming">{{$t('main.fullname')}} <img src="../assets/img/repair-logo.png" alt=""></div>
            <input class="input-text" maxlength="6" v-model="info.username" type="text" :placeholder="$t('main.pfiyname')">
            <div class="xingming">{{$t('main.phoneNums')}} <img src="../assets/img/repair-logo.png" alt=""></div>
            <input class="input-text" oninput="if(value.length>10)value=value.slice(0,11)" v-model="info.phone" type="number" :placeholder="this.$t('main.pfyci')">
            <div class="xingming">{{$t('main.upafd')}}</div>
             <div class="sc-btn">
                <div class="sc-btn-item" @click="upLoadImg">
                    <div v-if="!ds_data.pic">+</div>
                    <img v-else :src="showPic.url" alt="" />
                    <div v-show="showPic.status == 'loading'" class="loading_i">
                        <van-loading type="spinner" color="#fff" size="0.24rem"
                            vertical>{{$t('main.uploading')}}</van-loading>
                    </div>
                </div>
            </div>
            <textarea maxlength="200" class="textarea-text" v-model="info.remark" type="text" :placeholder="$t('main.pbdescbitem')"></textarea>
            <div class="tbn-wrap">
                <div class="btn-pop" @click="repairBtn">{{$t('main.submitbut')}}</div>
            </div>
        </div>
    </div>
    <privacy ref="privacy"></privacy>
  </div>
</template>

<script>
import privacy from "../components/privacy.vue"
export default {
    components: {
    privacy
  },
    data(){
        return{
            list:[],
            showPop:false,
            info:{
                username :"",
                phone:"",
                remark:""
            },
            ds_data:{
                pic:""
            },
            showPic:{
                url:"",
                status:""
            },
            placeholder:"请简单描述一下物品的基本情况\n最多200个字符",
        }
    },
    async mounted(){
        let token = localStorage.getItem('user_token')
            if(this.$route.query.code && !token){
            let querys= ''
            if(this.$route.query.phone){
                let phone =this.getQueryString('phone')
                let nickName = this.getQueryString('phone_code')
                querys = `&phone=${phone}&phone_code=${decodeURIComponent(nickName)}`
            }
            await this.axios.get('/auth/nt?code='+this.$route.query.code+querys).then((res)=>{
                if(res.data.code == -1 && res.data.msg == '请填写手机号'){
                    
                    this.$refs.privacy.openPopup()
                    return
                }
                window.localStorage.setItem('user_token',res.data.data.token)
            })
            history.replaceState(null, null, "/repair");
        }
        let url = location.protocol + '//' + location.host
		let param = this.$route.fullPath
        this.axios.post('/auth/wechatConfig', {
			url: url + this.$store.state.jssdkUrl,
			jsApiList: ['chooseImage', 'getLocalImgData']
		}).then((res) => {

			let json = JSON.parse(res.data.data.data)

			wx.config({
				debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
				appId: json.appId, // 必填，公众号的唯一标识
				timestamp: json.timestamp, // 必填，生成签名的时间戳
				nonceStr: json.nonceStr, // 必填，生成签名的随机串
				signature: json.signature, // 必填，签名
				jsApiList: json.jsApiList // 必填，需要使用的 JS 接口列表
			})

		})
        this.axios.post("/refuse/getBackPicList")
        .then((res)=>{
            this.list = res.data.data.list ? res.data.data.list : []
        })
       
    },
    methods:{
        getQueryString(name) {
			var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
			var r = window.location.search.substr(1).match(reg);
			if (r != null) return decodeURIComponent(r[2]);
			return null;
		},
        repairBtn(){
            this.info.pic = this.ds_data.pic
            if(this.info.username == "" ){
                weui.alert(this.$t('main.pfiyname'),{buttons:[{label:this.$t('main.confIrm')}]})
                return
            }else if(this.info.phone.length == 0){
                weui.alert(this.$t('main.piyphone'),{buttons:[{label:this.$t('main.confIrm')}]})
                return
            }else if(this.info.phone.length != 11){
                weui.alert(this.$t('main.phoneErr'),{buttons:[{label:this.$t('main.confIrm')}]})
                return
            }
            Object.keys(this.info).forEach((item)=>{

                if(!this.info[item]){
                    delete this.info[item]
                }
            })
            this.axios.post("/refuse/addCusComment",this.info)
            .then((res)=>{
                if(res.data.code){
                    weui.alert(res.data.msg,{buttons:[{label:this.$t('main.confIrm')}]})
                    return
                }
                weui.alert(this.$t('main.repairtips'),{buttons:[{label:this.$t('main.confIrm')}]})
                this.showPop = false
            })
        },
        async showPopBtn(){
            if(!this.$isWeiXin()){
				weui.alert('请在微信浏览器打开')
				return
			}
            let token = localStorage.getItem('user_token')
            if(token){
                this.showPop = true
            }else{
                this.$refs.privacy.showFlag()
            }
        },
        upLoadImg() {
			let _this = this

			wx.chooseImage({
				count: 1, // 默认9
				sizeType: ["compressed"], // 可以指定是原图还是压缩图，默认二者都有
				sourceType: ["album"], // 可以指定来源是相册还是相机，默认二者都有
				success: (res) => {

					var localIds = res.localIds; // 返回选定照片的本地 ID 列表，localId可以作为 img 标签的 src 属性显示图片
					this.showPic.status = 'loading'
					this.showPic.url = 'http://t.6a.com/87/01193881f683a494.png'
					wx.getLocalImgData({
						localId: localIds[0], // 图片的localID
						success: (res) => {
							var localData = res.localData; // localData是图片的base64数据，可以用 img 标签显示


							var canvas = document.createElement('canvas')
							var ctx = canvas.getContext('2d')
							var img = new Image();
							if (localData.indexOf(',') == -1) {
								localData = 'data:image/jpg;base64,' + localData
							}
							this.showPic.url = localData
							img.onload = () => {
								let image1
								if (img.width > 1024 || img.height > 1024) {
									if (img.width > img.height) {
										let wh = img.width / img.height
										canvas.width = 1024
										canvas.height = 1024 / wh
										ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
										let piccc = canvas.toDataURL("image/png")
										image1 = this.dataURLtoBlob(piccc);
									} else {
										let hw = img.height / img.width
										canvas.width = 1024 / hw
										canvas.height = 1024
										ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
										let piccc = canvas.toDataURL("image/png")
										image1 = this.dataURLtoBlob(piccc);
									}
								} else {
									image1 = this.dataURLtoBlob(localData);
								}
                                
								let param = new FormData();
								param.append("file", image1, 'test.png', {
									headers: {
										"Content-Type": "multipart/form-data",
									},
								});

								this.axios.post("/common/uploadImg", param).then((res) => {
									if (res.data.code == 0) {
										_this.ds_data.pic = res.data.data.url
										this.showPic.status = 'done'

									} else {

										weui.alert('图片太大上传失败',{buttons:[{label:this.$t('main.confIrm')}]})
									}


								});

							}
							img.onerror = () => {
								let image1 = this.dataURLtoBlob(localData);
								let param = new FormData();
								param.append("file", image1, 'test.png', {
									headers: {
										"Content-Type": "multipart/form-data",
									},
								});

								this.axios.post("/common/uploadImg", param).then((res) => {
									_this.ds_data.pic = res.data.data.url
								});
							}
							img.src = localData

						},
					});
				},
			});
		},
		dataURLtoBlob(dataurl) {
			var arr = dataurl.split(',')
			// console.log(arr)
			var mime = 'image/png'

			var bstr = arr[1] ? atob(arr[1]) : atob(arr[0])
			var n = bstr.length
			var u8arr = new Uint8Array(n);
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}
			return new Blob([u8arr], {
				type: mime
			})
		},
    
    }
}
</script>

<style scoped>
.out{
    width: 100vw;
    min-height: 100vh;
}
.wallpaper{
    font-size: 0;
    margin-bottom: 0.6rem;
}
.wallpaper img{
    width: 100%;
}
.btn-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    background: #732C2C;
    width: 100%;
    height: 0.6rem;
}
.btn{
    background: url(../assets/img/repair-pop.png) no-repeat center;
    background-size: 100% 100%;
    width: 70%;
    height: 0.45rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #732C2C;
}
.pop-wrap{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
.pop{
    background: url(../assets/img/repait-pop-bg.png) no-repeat center;
    background-size: 100% 100%;
    width: 3rem;
    padding: 0.15rem;
    box-sizing: border-box;
    border-radius: 0.05rem;
    position: relative;
}
.title{
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #E7CB99;
    font-size: 0.14rem;
}
.title img{
    width: 0.1rem;
    margin: 0 0.1rem;
}
.xingming{
    color: white;
    margin-top: 0.1rem;
    display: flex;
    align-items: center;
}
.xingming img{
    width: 0.08rem;
    height: 0.08rem;
    margin-left: 0.1rem;
}
.input-text{
    border: none;
    background: #E7CB99;
    width: 100%;
    height: 0.4rem;
    border-radius: 0.05rem;
    padding-left: 0.15rem;
    box-sizing: border-box;
    font-size: 0.14rem;
}
.textarea-text{
    border: none;
    background: #E7CB99;
    padding: 0.1rem 0.15rem;
    min-width: 100%;
    box-sizing: border-box;
    border-radius: 0.05rem;
    height: 1rem;
    font-size: 0.14rem;
    max-width: 100%;
}
.tbn-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.05rem;
}
.btn-pop{
    background: url(../assets/img/repair-pop.png) no-repeat center;
    background-size: 100% 100%;
    width: 80%;
    height: 0.4rem;
    display: flex;
    justify-content: center;
    line-height: 0.4rem;
    color: #824744;
    font-weight: 600;
}
.sc-btn {
	display: flex;
	align-items: flex-end;
    margin-bottom: 0.1rem;
}

.sc-btn-item {
	position: relative;
	width: 1rem;
	height: 1rem;
	line-height: 1rem;
	text-align: center;
	border-radius: 0.06rem;
	background: white;
	/* box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3); */
	font-size: 0.25rem;
	margin-left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 0.135rem;
    background-color: #E7CB99;
    color: #7f4442;
}

.sc-btn-item img {
	width: 100%;
	height: 100%;
	display: block;
	object-fit: contain;
}
.loading_i {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: #1989fa;
	font-size: 0.12rem;
	background: rgba(0, 0, 0, 0.6);
}
:deep(.van-loading__text) {
	font-size: 0.12rem;
	line-height: 0.12rem;
	margin: 0;
	margin-top: 0.1rem;
}
.header{
    position: fixed;
    top: 0.2rem;
    left: 0.2rem;
}
.header .back{
    background: url(../assets/img/repair-back.png) no-repeat center;
    background-size: 100% 100%;
    height: 0.3rem;
    width: 0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
.guanbi img{
	width: 0.25rem;
	position: absolute;
    right: 0.1rem;
    top: 0.1rem;
    z-index: 1;
}
</style>